<template>   
<div >
    <div slot="header" class="modal-header popup-header">
          <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
      </div>
      <div class="card card-custom" v-loading="loading">
        <div class="card-body  p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false" style="padding:0px !important">  
                          <el-row>                             
                             <el-col :span="24">
                               <el-form-item label="New Password" >
                                 <el-input v-model="form.password" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('type_name') ? 'error ':''" show-password></el-input>
                               </el-form-item>
                               <span class="form-err" v-if="form.errors.has('password')" >{{form.errors.get('password')}}</span>
                             </el-col>
                             <el-col :span="24">
                               <el-form-item label="Confirm Password" >
                                 <el-input v-model="form.confirm_password" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('type_name') ? 'error ':''" show-password></el-input>
                               </el-form-item>
                               <span class="form-err" v-if="form.errors.has('confirm_password')" >{{form.errors.get('confirm_password')}}</span>
                             </el-col>
                              <el-col :span="24">
                                <div class="row">
                                  <div class="col-sm-12 ">
                                    <button class="btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="changePassword()">{{btn_text}}</button>                
                                  </div>
                                </div>  
                              </el-col>

                          </el-row>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services';
export default {
    name: 'change_password',
  props: {
    
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Change Payment Password'}
	      }
	    }
  },
    data() {
    return {
        loading:false,
        error: null,
        btn_text : 'Change',
        form : new Form({      
        password:null, 
        confirm_password: null,      
      }), 

    }
    },
    methods: {
     closeForm() {
     this.$emit('close')
	     
        },
        changePassword: function() {               
     
            this.api_url = 'change/payment/password'
            this.form.post(this.api_url).then((response) => {
              if(response.status){
                  this.$emit('close')
                  this.$router.push({ path: '/employee/list' })
                  this.loading=false;
                  this.$showResponse('success', response.message);
              }
            })
          }, 
    }

}
</script>