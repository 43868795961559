<template>   
<div >
    <div slot="header" class="modal-header popup-header">
          <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
      </div>
      <div class="card card-custom" v-loading="loading">
        <div class="card-body  p-0">
          <div class="d-flex align-items-center mb-9 rounded p-5 bg-light-success">
             <div class="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Customer Default Password</a>
              <span class="text-muted font-weight-bold">{{ Customer_default_password }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-9 rounded p-5 bg-light-success">
             <div class="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Payment Password</a>
              <span class="text-muted font-weight-bold">{{ payment_password }}</span>
            </div>
          </div>
          
        </div>
    </div>
</div>
</template>

<script>
import {getPaymentPassword} from "@/api/employee";
export default {
    name: 'change_password',
  props: {
     
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Show Passwords'}
	      }
	    }
  },
    data() {
    return {
        loading:false,
        error: null,
        btn_text : 'Change',
        Customer_default_password : 'Customer@admin!#',
        payment_password : '',
        

    }
    },
    methods: {
     closeForm() {
     this.$emit('close')
	     
        },
        
    },
    created(){

      getPaymentPassword().then(response => {
          this.payment_password = response.data.data.password
        })
    }

}
</script>